// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
require("@rails/ujs").start()
var jQuery = require('jquery');
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import Highcharts from 'highcharts';
require("highcharts/modules/data")(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/offline-exporting")(Highcharts)
require("highcharts/modules/map")(Highcharts)
window.Highcharts = Highcharts;

// #= require activeadmin-async_panel


import Dropzone from "dropzone";
window.Dropzone = Dropzone;

Dropzone.options.layoutDropper = {
    clickable: true,
    dictDefaultMessage: "Sleep een .csv bestand hier naar toe of klik in dit vak",
    acceptedFiles: "text/csv,application/vnd.ms-excel",
    maxFilesize: 1,
    init: function() {
        this.on("success", function(f,r) {
            if (r.url) {
                window.location.replace(r.url)
            } else {
                // $("#error_message").html(r.err)
            }
        })
    }
}
